<template>
  <div class="mt-4 mx-3 row white-box px-3 py-4">
    <div class="white-box-top"></div>
    <div class="col-md-4 col-lg-2">
      <nav class="left-nav">
        <div
          class="items"
          v-scroll-spy-link="{ selector: 'a', class: 'btn-primary' }"
          v-scroll-spy-active="{ selector: 'a div', class: 'btn-primary' }"
        >
          <div class="items">
            <a href="#list"
              ><div class="btn w-100 btn-simple text-start">
                {{ $t("servers.servers") }}
              </div></a
            >
            <a href="#add"
              ><div class="btn w-100 btn-simple text-start">
                {{ $t("servers.addNewServer") }}
              </div></a
            >
          </div>
        </div>
      </nav>
    </div>
    <div class="col-md-8 col-lg-10" v-scroll-spy="{ offset: 120 }">
      <section id="list">
        <h2>{{ $t("servers.serverSettings") }}</h2>
        <h4>{{ $t("servers.servers") }}</h4>
        <OneByOneTable
          v-model:rows="tokenRows"
          v-model:items="tokens"
          type="Token"
          :show-add="false"
        />
      </section>
      <section id="new">
        <h4>{{ $t("servers.addNewServer") }}</h4>
        <div class="row text-start custom-input whitebg-input mt-4">
          <div class="row mb-3">
            <div class="col-12">
              <label class="form-label bold-12 text-gray">{{
                $t("servers.tokenName")
              }}</label>
              <input
                type="text"
                class="form-control remove-padding"
                v-model="NewServer.name"
              />
            </div>
            <div class="col-6">
              <label class="form-label bold-12 text-gray">{{
                $t("servers.webshops")
              }}</label>
              <v-select
                :options="webshops"
                v-model="NewServer.webshop_id"
                label="name"
                multiple
                :placeholder="$t(`servers.selectWebshop`)"
                :reduce="(c) => c.id"
                append-to-body
              />
            </div>
            <div class="col-6">
              <label class="form-label bold-12 text-gray">{{
                $t("servers.authToken")
              }}</label>
              <input
                type="text"
                class="form-control remove-padding"
                v-model="NewServer.auth_token"
              />
            </div>
            <div class="col-6">
              <label class="form-label bold-12 text-gray">IPv4</label>
              <input
                type="text"
                class="form-control remove-padding"
                v-model="NewServer.ip4"
              />
            </div>
            <div class="col-6">
              <label class="form-label bold-12 text-gray">IPv6</label>
              <input
                type="text"
                class="form-control remove-padding"
                v-model="NewServer.ip6"
              />
            </div>
          </div>
        </div>
        <div class="row mb-3 d-grid justify-content-end me-4">
          <button
            class="btn btn-type-2 btn-normal"
            v-on:click="createNewToken()"
          >
            <span>{{ $t("servers.create") }}</span>
          </button>
        </div>
        <hr class="my-4" />
      </section>
    </div>
  </div>
</template>

<script>
import OneByOneTable from "../lists/OneByOneTable";
import http from "@/modules/http";
import { useStore } from "vuex";
export default {
  name: "Webshops",
  components: { OneByOneTable },
  data() {
    return {
      store: useStore(),
      NewServer: {},
      tokenRows: [
        {
          name: "Token name",
          key: "name",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Webshops",
          key: "webshop_name",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "IPv4",
          key: "ip4",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "IPv6",
          key: "ip6",
          type: "string",
          show: true,
          editable: false,
        },
      ],
      tokens: [],
    };
  },
  computed: {
    webshops() {
      return this.store.state.webshops;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      http.fetch("/webshops/token").then((data) => {
        let rows = {};
        for (const row of data.data) {
          if (rows[row.name + "-" + row.ip4 + "-" + row.ip6] === undefined) {
            rows[row.name + "-" + row.ip4 + "-" + row.ip6] = row;
          } else {
            rows[row.name + "-" + row.ip4 + "-" + row.ip6].webshop_name +=
              ", " + row.webshop_name;
          }
        }
        this.tokens = rows;
      });
    },
    createNewToken() {
      http.fetch("/webshops/token", this.NewServer, true).then((data) => {
        console.log(data);
      });
    },
  },
};
</script>
